<template>
  <div class="bgcard">
    <div class="jortitle">
      <div class="jorcontent" @click="showsystem">
        <div class="system" :class="{ bgcolor: systemcontent }">
          <span>系统日志</span>
        </div>
        <div :class="{ triangle: true, activeColor: systemcontent }"></div>
      </div>
      <div class="jorcontent" @click="showoper">
        <div class="operation" :class="{ bgcolor: operationcontent }">
          <span>操作日志</span>
        </div>
        <div :class="{ triangle: true, activeColor: operationcontent }"></div>
      </div>
    </div>
    <div class="systemcontent" v-show="systemcontent">系统日志</div>
    <div class="operationcontent" v-show="operationcontent">操作日志</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      systemcontent: true,
      operationcontent: false,
      bgcolor: "#6d7071",
      activeColor: "#222628",
    };
  },
  methods: {
    showsystem() {
      this.operationcontent = false;
      this.systemcontent = true;
    },
    showoper() {
      this.operationcontent = true;
      this.systemcontent = false;
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  width: 100%;
  height: 100%;
  border: 0px;
  padding-top: 3px;
  box-sizing: border-box;
  border-radius: 2px;
  background: #5c5c5f;
}
.jortitle {
  display: flex;
  .jorcontent {
    display: flex;
    &:last-of-type {
      margin-left: -19px;
    }
  }
}
.system {
  width: 167px;
  height: 64px;
  background: #6d7071;
  border-radius: 2px 2px 2px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    line-height: 35px;
  }
}

.operation {
  width: 167px;
  height: 64px;
  background: #6d7071;
  border-radius: 2px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    line-height: 35px;
  }
}
.systemcontent,
.operationcontent {
  height: 800px;
  background: #222628;
  border-radius: 2px;
}
/* 激活样式 */
.bgcolor {
  background: #222628;
  z-index: 12;
}
/* 三角形样式 */
.triangle {
  width: 0;
  height: 0;
  border-bottom: 64px solid #6d7071;
  border-right: 38px solid transparent;
  z-index: 10;
}
.activeColor {
  border-bottom: 64px solid #222628;
}
</style>
